<template>
  <!-- src: ShopRegisterIndex -->
  <v-container style="padding-bottom: 24px">
    <!-- 语言切换 -->
    <v-menu
      v-if="data.show_language_switcher == 'on' && allLanguages.length > 1"
      offset-y
      :close-on-click="menuCloseOnClick"
      :elevation="2"
      z-index="99999"
    >
      <template #activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" style="display: inline-block">
          <span
            class="d-flex align-center"
            style="
              margin-bottom: 10px;
              padding: 10px;
              border: 1px solid #f5f5f5;
              border-radius: 12px;
            "
          >
            <span class="opacity-60">{{ userLanguageObj.name }}</span>
            <i class="las la-angle-down ms-1 fs-12" />
          </span>
        </div>
      </template>

      <v-list class="fs-13">
        <v-list-item
          v-for="(language, i) in allLanguages"
          :key="i"
          class="c-pointer d-flex align-center"
          @click="switchLanguage(language.code)"
        >
          <img
            :src="require(`@/assets/img/flags/${language.flag}.png`)"
            class="me-1 h-10px"
          />
          <v-list-item-title class="fs-13 opacity-60">
            {{ language.name }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <ShopCommonTopBar />

    <v-col
      cols="12"
      lg="6"
      md="8"
      sm="10"
      class=""
      style="display: flex; padding: 0; margin-bottom: 24px; flex-direction: column;"
    >
      <span class="mr-2 fs-20 primary-vi">{{ $t('already_a_seller') + '?' }}</span>

      <span
        class="fs-14 h-40px mt-4"
        style="
          color: #fff;
          cursor: pointer;
          padding: 0 12px;
          border-radius: 10px;
          display: inline-flex;
          align-items: center;
          width: fit-content;
          background-color: #85EEDF;
        "
        @click="routeToLogin"
      >
        <img
          :src="require('@/assets/img/shop-to-login.png')"
          style="width: 18px; height: 18px; margin-right: 10px;"
          class="ml-1"
        />
        <span style="white-space: nowrap; pt-2">{{ $t('click_to_log_in') }}</span>
      </span>
    </v-col>

    <!-- lg="6" md="8" sm="10" -->
    <v-col cols="12" style="padding: 0;">
      <v-form lazy-validation @submit.prevent="onSend">
        <v-card elevation="0">
          <v-card-title style="padding: 0 0 6px;">
            <span class="text-h6 fw-600 primary-vi">
              {{ $t('register_seller_account') }}
            </span>
          </v-card-title>
          <v-card-text style="padding: 16px 0">
            <div class="mb-4" v-if="isShowPhone" >
              <div class="mb-1 fs-18 fw-500 primary-vi">
                {{ $t('phone_number') }}
              </div>
              <vue-tel-input
                v-model="form.phone"
                v-bind="mobileInputProps"
                :auto-format="false"
                :preferred-countries="availableCountries"
                @country-changed="onCountryChanged"
                @validate="phoneValidate"
              >
                <template slot="arrow-icon" style="background: transparent">
                  <span style="margin: 0 3px">
                    {{ dialCode ? `+${dialCode}` : '' }}
                  </span>
                  <span class="vti__dropdown-arrow">&nbsp;▼</span>
                </template>
              </vue-tel-input>
              <div
                class="v-text-field__details mt-2 pl-3"
                v-if="$v.form.phone.$error"
              >
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      {{ $t('this_field_is_required') }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="v-text-field__details mt-2 pl-3"
                v-if="!$v.form.phone.$error && form.showInvalidPhone"
              >
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      {{ $t('phone_number_must_be_valid') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="" v-if="isShowEmail">
              <div class="fs-18 fw-500 primary-vi">
                {{ $t('email_address') }}
              </div>   
              <v-text-field
                :placeholder="$t('email_address')"
                type="email"
                :error-messages="emailErrors"
                v-model="form.email"
                @blur="$v.form.email.$touch()"
                hide-details="auto"
                class="vi-input"
                required
                outlined
              ></v-text-field>
            </div>

            <!-- changge register type -->
            <div
              v-if="showRegisterTypeSwitch"
              class="d-flex justify-end form-group mb-2"
              style="margin-top: 10px;"
            >
              <v-btn
                class="p-0 text-reset h-20px"
                type="button"
                elevation="0"
                @click="switchMode"
                style="background: transparent; border: 1px solid #85EEDF; border-radius: 10px;"
              >
                <span style="color: #85EEDF" class="fw-700; fs-16">
                  {{ isShowEmail ? $t('change_phone') : $t('change_email') }}
                </span>
                <img
                  :src="require('@/assets/img/change-white.png')"
                  style="width: 14px; height: 14px"
                  class="ml-3"
                />
              </v-btn>
            </div>
          </v-card-text>
        </v-card>

        <v-row style="margin-top: 100px" align="center" justify="center">
          <!-- <v-col cols="5"></v-col> -->

          <v-col style="display: flex; justify-content: flex-end">
            <v-btn
              class="px-12 mb-4 w-100 h-40px"
              style="max-width: 270px; border-radius: 10px; color: #ffffff"
              elevation="0"
              type="submit"
              color="#00CCAE"
              :loading="loading"
              :disabled="loading"
            >
              {{ $t('next') }}

              <i
                class="la la-arrow-right fs-20"
                style="position: absolute; right: -40px"
              ></i>
            </v-btn>
          </v-col>

          <v-col>  
            <div
              class="mb-4 text-center h-40px"
              style="
                max-width: 270px;
                color: #fff;
                background: #00CCAE;
                display: flex;
                border-radius: 10px;
                align-items: center;
                justify-content: center;
              "
            >
              <img
                :src="require('@/assets/img/switch-user.png')"
                width="22px"
                height="22px"
                class="mr-1"
              />
              <router-link to="/" class="fs-14 fw-500" style="color: #fff;">
                {{ $t('switch_to_user') }}
              </router-link>
            </div>
          </v-col>
        </v-row>

        <div class="mb-4 text-center" style="color: #434343">
          {{ $t('by_signing_up_you_agree_to_our') }}
          <router-link
            :to="{
              name: 'CustomPage',
              params: { pageSlug: 'terms' }
            }"
            class="text-decoration-underline"
            style="color: #434343"
          >
            {{ $t('terms_and_conditions') }}
          </router-link>
        </div>

        <div class="text-center text-decoration-underline fs-14">
          <a :href="contactUrl" target="_blank" class="text-reset opacity-60">
            <!-- <i class="la la-phone" /> -->
            <span>{{ $t('help_line_link') }}</span>
            <!-- <span>{{ data.helpline }}</span> -->
          </a>
        </div>
      </v-form>
    </v-col>

    <v-dialog v-model="dialogSwitch" persistent width="310px">
      <v-card class="dialog-container">
        <div class="dialog-close c-pointer" @click="dialogSwitch = false">
          <i class="la la-close fs-20"></i>
        </div>

        <!-- todo: 百分比 -->
        <drag-verify-img-chip
          ref="dvicChip"
          :imgsrc="require('@/assets/img/verified_image.png')"
          :isPassing.sync="isPassing"
          :barWidth="40"
          :text="$t('please_hold_down_the_slider_and_drag')"
          :successText="$t('verificate_pass')"
          :failTip="$t('verification_fails_tips')"
          :successTip="
            $t('verification_passed_more_than_of_users', { scale: '85%' })
          "
          handlerIcon="la la-arrow-right"
          successIcon="la la-check"
          @refresh="refreshVerifyImage"
          @passcallback="verificationPass"
        />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import { VueTelInput } from 'vue-tel-input'

// component
import ShopCommonTopBar from '@/components/shop/ShopCommonTopBar'
import DragVerifyImgChip from '@/components/auth/DragVerifyImgChip'

// vuex
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'ShopRegisterIndex',

  components: {
    ShopCommonTopBar,
    VueTelInput,
    DragVerifyImgChip
  },

  data() {
    return {
      data: {},

      menuCloseOnClick: true,

      mobileInputProps: {
        inputOptions: {
          type: 'tel',
          placeholder: 'phone number'
        },
        dropdownOptions: {
          showDialCodeInSelection: false,
          showFlags: true,
          showDialCodeInList: true
        },
        autoDefaultCountry: false,
        validCharactersOnly: true,
        mode: 'international'
      },

      form: {
        phone: '',
        email: '',
        showInvalidPhone: false
      },

      // 是否展示切换手机/邮箱开关
      showRegisterTypeSwitch: false,

      isShowPhone: false,

      isShowEmail: true,

      // 图片验证是否通过
      isPassing: false,

      // dialog 开关
      dialogSwitch: false,

      // 国家区号
      dialCode: '',

      loading: false
    }
  },

  watch: {
    'form.phone'(value) {
      if (String(value).indexOf('0') === 0) {
        this.form.phone = this.form.phone.substring(1)
      }
    }
  },

  computed: {
    ...mapGetters('app', [
      'h5Url',
      'getSellerH5Url',
      'allLanguages',
      'userLanguageObj',
      'availableCountries',
      'contactUrl'
    ]),

    emailErrors() {
      const errors = []
      if (!this.$v.form.email.$dirty) return errors
      !this.$v.form.email.required &&
        errors.push(this.$i18n.t('this_field_is_required'))
      !this.$v.form.email.email &&
        errors.push(this.$i18n.t('this_field_is_required_a_valid_email'))
      return errors
    }
  },

  validations: {
    form: {
      email: { required, email },
      phone: { required }
    }
  },

  methods: {
    ...mapActions('app', ['setLanguage']),
    ...mapMutations('app', ['setContactUrl']),

    // 验证图片刷新
    refreshVerifyImage() {},

    // 验证通过
    verificationPass() {
      this.sendVerificationCode()
    },

    // 直接去注册
    toregister() {
      const params = {
        name: 'ShopPersonalInformation',

        query: {}
      }

      if (this.isShowPhone) {
        params.query.type = 'phone'
        params.query.value = `+${this.dialCode}${this.form.phone.replace(
          /\s/g,
          ''
        )}`
      } else {
        params.query.type = 'email'
        params.query.value = this.form.email
      }

      // 如果有邀请码带上邀请码
      if (this.referralCode) {
        params.query = {
          referral_code: this.referralCode
        }
      }

      this.$router.push(params)
    },

    // 根据当前的注册，自动发送对应的验证码
    async sendVerificationCode() {
      this.loading = true

      try {
        const methods = this.isShowPhone ? 'sms/send' : 'email/send'

        const params = {
          tag: 'register'
        }

        if (this.isShowPhone) {
          params.phone = `+${this.dialCode}${this.form.phone.replace(
            /\s/g,
            ''
          )}`
        } else {
          params.email = this.form.email
        }

        const {
          data: { result, message }
        } = await this.call_api('post', methods, params)

        if (result) {
          const params = { name: 'ShopInformation', query: {} }

          // 如果有邀请码带上邀请码
          if (this.referralCode) {
            params.query = {
              referral_code: this.referralCode
            }
          }

          if (this.isShowPhone) {
            params.query.type = 'phone'
            params.query.value = `+${this.dialCode}${this.form.phone.replace(
              /\s/g,
              ''
            )}`
          } else {
            params.query.type = 'email'
            params.query.value = this.form.email
          }

          return this.$router.push(params)
        }

        this.snack({
          message: message,
          color: 'red'
        })
      } catch (error) {
        console.log('send verification code fail: ', error)

        this.snack({
          message: this.$t('verification_code_has_send_fail'),
          color: 'red'
        })
      }

      this.loading = false
    },

    async onSend() {
      if (this.isShowPhone) {
        this.$v.form.$touch('phone')

        if (this.$v.form.phone.$error || this.form.showInvalidPhone) return
      } else {
        this.$v.form.email.$touch()

        if (this.$v.form.email.$error) return
      }

      if (!this.registerVerifySwitch) {
        const {
          data: { result, message }
        } = await this.call_api('post', 'user/check/account', {
          type: this.isShowPhone ? 'phone' : 'email',
          value: this.isShowPhone ? this.form.phone : this.form.email,
          tag: 'register'
        })

        if (result) {
          return this.toregister()
        }

        return this.snack({
          message,
          color: 'red'
        })
      }

      this.verificationPass()
      // this.dialogSwitch = true
      // this.$nextTick(() => {
      //   this.$refs.dvicChip.reset()
      // })
    },

    phoneValidate(phone) {
      this.form.showInvalidPhone = !phone.valid
    },

    // 电话区号改变
    onCountryChanged({ dialCode }) {
      this.dialCode = dialCode
    },

    switchLanguage(locale) {
      if (this.$i18n.locale !== locale) {
        this.setLanguage(locale)
        window.location.reload()
      }
    },

    routeToUser() {
      this.$router.push({ name: 'Home' })
    },

    routeToLogin() {
      if (
        window.navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
      ) {
        window.location.href = this.getSellerH5Url
      } else {
        window.location.href = this.h5Url
      }
    },

    routeToRegister() {
      this.$router.push({ name: 'ShopInformation' })
    },

    // 注册配置
    async registerConfig() {
      try {
        const {
          data: {
            data: { seller_register_type, seller_register_verify }
          }
        } = await this.call_api('get', `register/config?tag=seller`)

        switch (seller_register_type) {
          case 2:
            this.isShowEmail = false
            this.isShowPhone = true
            break
          case 3:
            this.showRegisterTypeSwitch = true
            break
          default:
            break
        }

        // 注册验证
        this.registerVerifySwitch = !!seller_register_verify
      } catch (error) {
        console.log('register config error: ', error)
      }
    },

    async getDetails() {
      const res = await this.call_api('get', `setting/header`)

      if (res.status === 200) {
        this.data = res.data
        this.setContactUrl(this.data.helpline_link)
      }
    },

    switchMode() {
      if (this.isShowEmail) {
        this.isShowPhone = true
        this.isShowEmail = false
      } else {
        this.isShowPhone = false
        this.isShowEmail = true
      }
    }
  },

  created() {
    const query = this.$route.query

    if (query.referral_code) {
      this.referralCode = query.referral_code
    }

    this.getDetails()
    this.registerConfig()
  }
}
</script>

<style lang="scss" scoped>
.dialog-container {
  padding: 30px;
}

.dialog-close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 6px;
}

::v-deep .drag-verify-container {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.card-text {
  color: #000;
}

.fc-vi {
  color: #FF3B3B;
}

.theme--light.v-card {
  background: transparent !important;
  color: #FF3B3B;
}

.vue-tel-input {
  margin-top: 10px;
  border-radius: 10px !important;
  border: 2px solid var(--02, #00CCAE) !important;
}

.vti__dropdown.disabled, .vti__dropdown.open {
  background: transparent;
}

.vti__dropdown:hover {
  background: transparent;
}

.vi-input  {
  margin-top: 10px;
  border-radius: 10px;
  // border: 2px solid #6052E2;
}

</style>
